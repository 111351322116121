module.exports = [{
      plugin: require('/codebuild/output/src358152346/src/govbook/node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/codebuild/output/src358152346/src/govbook/src/intl","languages":["en","es"],"defaultLanguage":"en","redirect":true},
    },{
      plugin: require('/codebuild/output/src358152346/src/govbook/node_modules/gatsby-plugin-lunr/gatsby-browser.js'),
      options: {"plugins":[],"languages":[{"name":"en"}],"fields":[{"name":"County","store":false,"boost":30},{"name":"UnitName","store":false,"boost":10},{"name":"ExecName","store":false,"boost":10},{"name":"Description","store":false},{"name":"Code","store":true}],"resolvers":{"Contacts":{}},"filename":"search_index.json","fetchOptions":{"credentials":"same-origin"}},
    },{
      plugin: require('/codebuild/output/src358152346/src/govbook/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-2350659-1"],"gtagConfig":{"anonymize_ip":true,"send_pageview":false},"pluginConfig":{"exclude":["/*"]}},
    },{
      plugin: require('/codebuild/output/src358152346/src/govbook/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/codebuild/output/src358152346/src/govbook/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
